@import './fonts/fonts.css';

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100vw;
  background: #FFFBEF;
  position: relative;
}

html, body {
  overflow-x: hidden;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, input, textarea, select{
  outline: none;
  background: none;
  border: none;
  font-family: Nunito, sans-serif;
  cursor: pointer;
  resize: none;
}

*{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  z-index: 1;
}