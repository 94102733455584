@font-face {
    font-family: 'Russo One';
    src: local('Russo One'), local('RussoOne'), url('russoone.woff2') format('woff2'), url('russoone.woff') format('woff'), url('russoone.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url('Robotocondensed.woff2') format('woff2'), url('Robotocondensed.woff') format('woff'), url('Robotocondensed.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}